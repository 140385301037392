.longCard{
    width: 80vw;
    
    min-height: 20vh;
    margin-top:3vh;
    background-color:rgb(255, 255, 255);
    justify-content: center;
    box-shadow: 1px 1px 5px 1px #c0c0bd;
    align-items: center;
    border-radius: 30px;
    display:flex;
    position:relative;
    padding-top: 5vh;
    z-index: 3;
}


.longCard:hover{
  width: 80vw;
  background: linear-gradient(180deg, rgba(73, 168, 145, 0.7) 0%, #2C878D 100%), #C4C4C4;
  min-height: 20vh;
  margin-top:3vh;
  background-color:rgb(255, 255, 255);
  justify-content: center;
  box-shadow: 2px 2px 5px 3px #c0c0bd;
  align-items: center;
  border-radius: 30px;
  display:flex;
  
  padding-top: 5vh;
  z-index: 3;
}





.inputStyle{
  background-color: white;
  border-radius:10px;

  height:50px;
  margin:0vh 1vh 2vh 1vh;
  text-align: center;
  border: 1px solid rgb(172, 169, 169);
  margin-top: 1vh;
}

select:focus {
  box-shadow: 0 0 0 0;
  outline: 0;
}

.select {
  border-radius:10px;
  height: 5vh;
}


.infoCard{
  
  height: 40vh;
  margin:1vh;
  background-color: #ffffff;
  
  justify-content: center;
  box-shadow: 2px 2px 5px 2px #e3edee;
  align-items: flex-start;
  border-radius: 10px;
  display:flex;
  padding:20px;
  padding-top: 5vh;
  margin-top: 5vh;
  z-index: 3;
 
}


.infoCard:hover{
  
  height: 40vh;
  margin:1vh;
  background: linear-gradient(180deg, rgba(73, 168, 145, 0.7) 0%, #2C878D 100%), #C4C4C4;
  justify-content: center;
  box-shadow: 0px 0px 5px 2px  rgba(184, 209, 204, 0.7);
  align-items: flex-start;
  border-radius: 10px;
  display:flex;
  padding:20px;
  padding-top: 5vh;
  margin-top: 3vh;
  color: white !important;
  z-index: 3;
}


@media(max-width:450px){
  .infoCard{
  
    height: 45vh;
    margin:1vh;
    background-color: #ffffff;
    
    justify-content: center;
    box-shadow: 2px 2px 5px 2px #e3edee;
    align-items: flex-start;
    border-radius: 10px;
    display:flex;
    padding:20px;
    padding-top: 5vh;
    margin-top: 5vh;
    z-index: 3;
   
  }
  
  
  .infoCard:hover{
    
    height: 45vh;
    margin:1vh;
    background: linear-gradient(180deg, rgba(73, 168, 145, 0.7) 0%, #2C878D 100%), #C4C4C4;
    justify-content: center;
    box-shadow: 0px 0px 5px 2px  rgba(184, 209, 204, 0.7);
    align-items: flex-start;
    border-radius: 10px;
    display:flex;
    padding:20px;
    padding-top: 5vh;
    margin-top: 3vh;
    color: white !important;
    z-index: 3;
  }
  
}

.inforow{
  flex-direction: row;
  margin-top:1vh;
}

.infoCard img{
    height:25vh;
    width: auto;
    position:absolute;
    bottom:2px;
    right:2px;
    border-radius: 10px;
}

.longCard img{
  height:28vh;
  width: auto;
  position:absolute;
  top:1vh;
  right:4vh;
  border-radius: 10px;
}

@media(max-width: 495px) and (min-width:350px){
  h4{
    font-family: Roboto,sans-serif;
    font-size: 20px;
  }
  
  
    h3{
    font-family: Roboto,sans-serif;
    font-size: 25px;
  }
  
  
    h6{
    font-family: Roboto,sans-serif;
    font-size: 15px;
  }

}

@media(max-width: 349px) {
  h4{
    font-family: Roboto,sans-serif;
    font-size: 20px;
  }
  
  
    h3{
    font-family: Roboto,sans-serif;
    font-size: 25px;
  }
  
  
    h6{
    font-family: Roboto,sans-serif;
    font-size: 12px;
  }

}



h4{
  font-family: Roboto,sans-serif;
}


  h3{
  font-family: Roboto,sans-serif;
}


  h6{
  font-family: Roboto,sans-serif;
}

  