.dashboard_body{
    height: 100vh;
    width: 100vw;
    background-color: white;
}

/* Css da barra lateral onde tem a navegação */
.to-print {
    display: none;
}
.dashboard_options{
    height: 100%;
    width: 100%;
    background: linear-gradient(180deg, #4A959A  0%, #4A959A 100%), #FFF5F5;
    padding:0px;
    border-radius: 0px 0px 20px;
 
}

.dashboard_options_front{
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #282828;
    position:absolute;
    border-radius: 60px 0px 20px;
    justify-content: start;
    align-items: center;
}

.dashboard_logo_image{
    height: auto;
    width: 100%;
    margin-top: 4.5vh;
}

.dashboard_patient_image{
    height: auto;
    width: 60%;
    margin-top: 4.5vh;
} 

.dashboard_patient_image:hover{
    height: auto;
    width: 65%;
    margin-top: 4vh;
} 

/*css do quadro onde ficam as informaçoes*/


.dashboard_informations{
    height: 100%;
    width: 90%;
    overflow-y: scroll;
}

.dashboard_navbar {
    flex-direction: row;
    margin: 4.5vh;
    margin-bottom: 0px;
    display: flex;
    justify-content:left;
    align-items: flex-start;

}
.dashboard_navbar h2{
    font-family: Montserrat;
    color:#282828;
    font-weight: 900;

}

.dashboard_navbar h2 span{
    border-bottom: 3px solid #4A959A;

}


.dashboard_subject{
    flex-direction: row;
    margin: 5vh;
    margin-bottom: 0px;
    padding-left: 2vh;
    display: flex;
    justify-content:left;
    align-items: flex-start;
}


.dashboard_subject h4{
    font-family: Montserrat;
    color:#282828;
    font-weight: 700;
}

.dashboard_table{
    margin: 3vh;
}
