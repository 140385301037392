.App {
  text-align: center;
}

.App-logo {
  height: 25vmin;
  pointer-events: none;
}

.logo{
margin-right:20px;

}
.marg{
  margin:0px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 5s linear;
  }
}
.just{
  display:flex;
  justify-content: center;
  z-index:10;
}


.yellowCard{
  width: 60vw;
  height: 50vh;
  background-color:white;
  justify-content: center;
  box-shadow: 1px 0px 5px -1px #ACABA3;
  align-items: center;
  border-radius: 30px;
  display:flex;
  padding:20px;
  padding-top: 5vh;
  z-index: 3;
}

.container {
  display: flex;
  padding: 2.5vh;
  
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /* overflow-y: hidden; */
  
}

h4.col-12 {
  text-align: center;
}

h6.col-12 {
  text-align: center !important;
}

.bg {
  position: absolute;
  width:100%;
  height: 100vh;
  background: linear-gradient(180deg, #3E917d 50%, #2C878D 100%), #C4C4C4;
  
}
.bg-img{
  position:absolute;
  z-index: 2;
  left: 1vh;
  bottom:1vh;
  width: auto;
  height: 20vh;
}

.bgimg{
 
  width: auto;
  height: 20vh;
}

@media(max-width:866px){
  .bg-img{
   display:none;
  }
  
}
@media(max-width:531px){
.seta{
  width: 30% !important;
}}


@media(min-width:867px){
  .bgimg{
   display:none;
  }
  
}

.bg2 {
  width:100%;
  height: 100vh;
  /* overflow-x: hidden; */
  /* background-color:red; */
}


.blackCard{
  width: 50vw;
  height: 70vh;
  margin-top: 5vh;
  background-color:#2e2d2d;
  justify-content: center;
  
  align-items: center;
  border-radius: 30px;
}

.calendar {
  align-items: center;
  justify-content:flex-start;
  height: 50vh;
}

.title {
  margin-bottom: 5vh;
}

.react-calendar__navigation button {
  background-color: transparent !important;
  border-color: transparent !important;
}




.calendar.react-calendar__navigation__next2-button {
  display: none;
}



@media(max-width:767px){
  .ciblock{
    display: none!important;
    
  }
  }  

@media(min-width:877px){
.inputcard{
  padding:2vh;
}  
.whiteCard{
  width: 80vw;
  min-height: 70vh;
  margin-top:5vh;
  box-shadow: 1px 0px 5px -1px #ACABA3;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
  align-items: center;
  border-radius: 30px;
  flex-direction: column;
  overflow-y: hidden;

}

.imgCi{
height:40vh;
width:auto;

}

.mobile{
  display: none!important;
}

}

@media(min-width:531px){
  .desknone{
    display: none !important;
  }}



@media(max-width:530px){
  .mobnone{
    display: none !important;
  }

  .btnC{
    margin: 5px !important;

  }

}

.mobile{
  display:flex; 
  justify-content: center;
}
.mobile img{

  height:25vh;
  width:auto;
  margin: 0.5vh;

}
.time{

  
}
.time .mincard{
  z-index: 999999;
  background: linear-gradient(180deg, rgba(73, 168, 145, 0.7) 0%, #2C878D 100%), #C4C4C4;
  width: 60px;
  height: 30px;
  margin:8px 8px 8px 8px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Montserrat;
  font-size:15px;
  font-weight: 700;
  border: 2px solid white;
  color:black;

}

.time{
  padding-bottom: 1vh;
}

.mincard{
  background: linear-gradient(180deg, rgba(73, 168, 145, 0.7) 0%, #2C878D 100%), #C4C4C4;
  align-self: center;
  height: 30px;
  width: 60px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Montserrat;
  font-size:15px;
  font-weight: 700;
  border: 2px solid white;
  
}


.react-calendar__month-view__weekdays__weekday abbr{
  color:black;
  display:flex;
  justify-content: center;
  font-family: poppins;
  font-size:15px!important;
 text-decoration: unset!important;
}



.mincard-d{
  background-color: rgba(171, 220, 208, 0.3);
  color: #ACABA3;
  align-self: center;
  height: 30px;
  width: 60px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Montserrat;
  font-size:15px;
  font-weight: 700;
  border: 2px solid white;
  pointer-events: none;
}

.mincard:hover{
  background-color:#000000;
  border: 2px solid #ebebeb;
  color:white;
  background: black;
}


.daycard{
  background-color:#ffffff;
  width: 50px;
  height: 40px;
  margin:8px 6px 0px 6px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color:rgb(0, 0, 0);
  font-family: Poppins;
  font-size: 16px;
  font-style:normal;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;


}

.colSeta{
  display: flex;
  justify-content: center;
  align-items: center;

}

.seta{
  margin-bottom: 5vh;
  width: 12%;
  height:auto;
}

.big {
  width: 25%;
}

.invisible {
  display: none;
  border: none;
  background-color: transparent;
}


.row.test.d-flex.justify-content-center {
  width: 100vw;
}

.scrolling-wrapper {
  justify-content: center;
}

@media (max-width: 991px) {
  .scrolling-wrapper {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      
  }

  .scrolling-wrapper {
      justify-content: unset !important;
  }
}

/* ------------------------ Nav bar -------------------
-------------------------------------------------------*/
.img-nav{
  width: auto;
  max-height: 75px;
}

.nav-ul{
font-family: Montserrat;
font-size: 17px;
font-style: normal;
font-weight: 700;
line-height: 18px;
letter-spacing: 0em;
text-align: left;
color:white;


}

.row {
  display: flex;
  flex-direction: row;
  z-index: 3;
  align-items: center;
}

.nav-ul:hover{
  font-family: Montserrat;
  font-size: 17.5px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color:white;
  border-color:#FFD500;
  
  }

.nav-menu{

height:80px;
background-color: #2e2d2d;
color:white;
font-size: 20px;
padding:15px;
justify-content: left;
align-items:stretch;
flex-direction:row;
display:flex;
padding-top: 10px;
}

li {
  list-style-type: none;
  margin-right:30px;
}

.nav-menu ul{
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: auto!important;
  font-style: normal!important;
  text-decoration-line:transparent;
}

a:-webkit-any-link {
  color: white;
  cursor: pointer;
  text-decoration: unset!important;
}

/*------------------------ fim nav bar ---------------------*/

input:focus {
  box-shadow: 0 0 0 0;
  outline: 0;
}


button:focus {
  box-shadow: 0 0 0 0;
  outline: 0;
}

.teste{

  background-color: #FFD500;
}

 .in{
  border: transparent;
  border-radius:10px;
  height:30px;
  width:250px;
  padding-left: 10px;

}

.confcard{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top:5vh;
}



.imgStream{
  height:auto;
  width:250px;
}

.inputcard h6{
  font-size: 14px;
}

input.input.col-3.col-lg-3.inConf {
  padding: 0px;
  margin: 0px;
  height: 30px;
}

.direction {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

input.input.col-7.col-lg-7.inConf {
  padding: 0px;
  margin: 0px;
  height: 30px;
}

.inConf{
  background-color: white;
  border-radius:5px;
  height:20px;
  margin:0vh 1vh 2vh 1vh;
  padding: 3px;
  text-align: center;
  border: 1px solid rgb(172, 169, 169);
}


.inConf-big {
  background-color: white;
  border-radius:10px;
  height:100px !important;
  margin:0vh 1vh 2vh 1vh;
  text-align: center;
  border: 1px solid rgb(172, 169, 169);
}

.imagem{
 margin-bottom:10px;
 height:20vh;
 width:auto;

}

.in:hover {
  border: transparent;
  border-radius:10px;
  height:30px;
  width:250px;
  padding-left: 10px;
}


.btnE{

  border: transparent;
  border-radius:10px;
  height:35px;
  width:155px;
  display: flex;
  justify-content: center;
  background-color: #49a59d;
  color:black;
  align-items: center;
  font-size: 15px;
  font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
  border: 2px solid #000000;
}


.btnE:hover{
background-color: #357a75;
border-color: #357a75;
transition: all 0.1s ease-in;
font-size: 16px;
}

.btnA{

  border: transparent;
  border-radius:10px;
  height:35px;
  width:155px;
  display: flex;
  justify-content: center;
  background-color: #f0e2a0;
  color:rgb(102, 101, 101);
  align-items: center;
  font-size: 15px;
  font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
  margin-top:30px;
  border: 2px solid white;
}

.pcard{
  margin-top:0.5vh;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
}


.btnC{

  border: transparent;
  border-radius:10px;
  height:35px;
  width:155px;
  align-self: center;
  display: flex;
  justify-content: center;
  background: linear-gradient(180deg, rgba(73, 168, 145, 0.7) 0%, #2C878D 100%), #C4C4C4;
  color:black;
  align-items: center;
  font-size: 15px;
  font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
  margin:2vh;
  border: 2px solid white;
}

@media(max-width:876px) and (min-width:350px){
.yellowCard{
  width: 90vw;
  height: 50vh;
  background-color:white;
  justify-content: center;
  box-shadow: 0px 0px 5px 0px #ACABA3;
  align-items: center;
  border-radius: 30px;
  display:flex;
  padding:20px;
  padding-top: 5vh;
  z-index: 3;
}}

@media (max-width:349px){
  .yellowCard{
    width: 90vw;
    height: 55vh;
    background-color:white;
    justify-content: center;
    box-shadow: 0px 0px 5px 0px #ACABA3;
    align-items: center;
    border-radius: 30px;
    display:flex;
    padding:20px;
    padding-top: 5vh;
    z-index: 3;
  }}

@media(max-width:876px){
  .borderline{
    display: none;
  }

  

  .confcard{
    
    margin-top:0vh;
  }

}
.borderline{
  border-right: 0.5px solid rgb(167, 163, 163);
}



.btnC:hover{
background: black;
border-color:   black;
color:white;
transition: all 0.1s ease-in;
font-size: 16px;
border-radius:8px;
}

.btnC:focus {
  box-shadow: 1px 1px 3px 1px #2C878D;
  }

  

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
  
}

.progress-container {
  display: flex;
  height: 20vh;
  justify-content: center;
}

.ball {
  height: 7vh;
  width: 7vh;
  border: 2px solid #bee9ec;
  border-radius: 100%;
  font-weight: bold;
  color: rgba(73, 168, 145, 0.7);
  display: flex;
  text-align: center;
  background-color: white;
  justify-content: center;
  align-items: center;
  z-index:3;
  -moz-transition:color .2s ease-in;
  -o-transition:color .2s ease-in;
  -webkit-transition:color .2s ease-in;
}

.active {
  background-color: black !important;
  background: linear-gradient(18deg, rgba(73, 168, 145, 0.7) 20%, #2C878D 80%), #797979;
  border: 0px solid white;
  color: white;
}

.line{
  width:4vh;
  height:50%;
  
  border-bottom: 2px solid #bee9ec;
  -moz-transition:color .5s ease-in;
  -o-transition:color .50s ease-in;
  -webkit-transition:color .5s ease-in;
  z-index:1;

}

.line1{
  width:4vh;
  height:50%;
 
  border-bottom: 2px solid #2C878D;
  -moz-transition:color .5s ease-in;
  -o-transition:color .5s ease-in;
  -webkit-transition:color .5s ease-in;
  z-index:1;

}


@media(max-width:530px){
  .ball {
    height: 4.5vh;
    width: 4.5vh;
    border: 2px solid #bee9ec;
    border-radius: 100%;
  
    font-weight: bold;
    color: black;
    display: flex;
    text-align: center;
    background-color: white;
    justify-content: center;
    align-items: center;
    z-index:3;
  
    -moz-transition:color .2s ease-in;
    -o-transition:color .2s ease-in;
    -webkit-transition:color .2s ease-in;
  }
  
  .active {
    background-color: black !important;
    background: linear-gradient(180deg, rgba(73, 168, 145, 0.7) 0%, #2C878D 100%), #797979;
    color: white;
  }
  
  .line{
    width:2vh;
    height:50%;
    border-bottom: 2px solid black;
  
  }

}

@media(max-width:876px) and ( min-width:531px){
  .ball {
    height: 6vh;
    width: 6vh;
  }

}

.error-container {
  height: 10px;
  display: flex;
  flex-direction: column;
}

/* Thanks Page */ 

.thanksPage{
margin-top: 0.5vh;
margin-bottom: 0vh;
}

.thanksPage h4{
  font-size: 20px;
  font-weight: lighter;
  font-family: poppins;
}

.imgthanks{
  height:5vh;
  width: auto;
}

@media(max-width:876px) and ( min-width:531px){

  .thanksPage{
    margin-top: 2vh;
    margin-bottom: 5vh;
    }


}

@media(max-width:530px) {

  .thanksPage{
    margin-top: 2vh;
    margin-bottom: 2vh;
    }
    
    .thanksPage h4{
      font-size: 17px;
      font-weight: lighter;
      font-family: poppins;
      
    }

    .thanksPage h2{
      margin-bottom: 3vh;
      margin-top: 3vh;
      font-size: 22px;
      font-weight: lighter;
      font-family: poppins;
      
    }
    
    .imgthanks{
      height:5vh;
      width: auto;
    }

    .imgthankslg{
      height:20vh;
      width: auto;

    }

}